// Import the functions you need from the SDKs you need
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAhfQ2_UkITjr565XsM--imf0jvXyq547Q",
    authDomain: "funnygrocery-ad164.firebaseapp.com",
    projectId: "funnygrocery-ad164",
    storageBucket: "funnygrocery-ad164.appspot.com",
    messagingSenderId: "620352073699",
    appId: "1:620352073699:web:d90b5d495feadd700f3ad6",
    measurementId: "G-PHT11SB5SC"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const firestore = getFirestore(app)
export const auth = getAuth(app);
export const analytics = getAnalytics(app);