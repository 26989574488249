import { useEffect, useRef, useState } from "react"
import { GroceryItem, GroceryItemProps } from "./GroceryItem"
import { ParticipantProps } from "./Participant"
import { TextField } from "@mui/material";
import { serverManager } from "../controller/serverManager";
import { Buffer } from 'buffer';
export type ActiveGroceryListProps =
    {
        id: string
        creatorName?: string,
        creatorEmail?: string,
        date: number,
        participants?: ParticipantProps[],
        items: GroceryItemProps[],
        canBeUpdate?: boolean,
        visible: () => void
    }
export function ActiveGroceryList(props: ActiveGroceryListProps) {
    const refInputAddElement = useRef<HTMLInputElement>(null)
    const [_endlist, setEndList] = useState(false);
    const [_groceryItemList, setGroceryItemList] = useState<GroceryItemProps[]>([])
    let prevGroceryItemList = usePreviousValue(_groceryItemList)


    function usePreviousValue(value: GroceryItemProps[]) {
        const ref = useRef<GroceryItemProps[]>();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }
    async function deleteAciiveGroceryList() {

        const response = window.confirm("Voulez-vous vraiment supprimer cette liste ?");

        if (response) {
            if (props.id) {
                await serverManager.deleteGroceryList(props.id)
                setTimeout(() => {
                    window.location.reload()
                }, 200)
            }
        } else {
            alert("Cancel was pressed");
        }
    }


    function updateGroceryitem(name: string, quantity: number, bought: boolean) {
        let newItemObj = {
            name: name,
            quantity: quantity,
            bought: bought
        }

        let index = _groceryItemList.findIndex((item) => item.name === newItemObj.name)

        if (index === -1) {
            return setGroceryItemList({ ..._groceryItemList, ...newItemObj })
        }

        if (index !== -1 && quantity === 0) {
            return setGroceryItemList(_groceryItemList.filter((elem, _index) => _index !== index))
        }

        return setGroceryItemList(_groceryItemList.map((item) => {
            if (item.name === newItemObj.name) {

                return { ...item, ...newItemObj }

            }
            return item
        }))
        //setallGroceryItems({ ...allGroceryItems, [name]: newItem });
    }

    useEffect(() => {
        async function getItems() {

            let items = await serverManager.getGroceryListItems(props.id)
            setGroceryItemList(items.map(item => item as GroceryItemProps))
        }
        getItems()
    }, [props.id])

    useEffect(() => {

        if (prevGroceryItemList !== undefined) {

            let diffprevGroceryItemList = prevGroceryItemList.filter(elm => !_groceryItemList.map(elm => JSON.stringify(elm)).includes(JSON.stringify(elm)));

            let diff_groceryItemList = _groceryItemList.filter(elm => {
                if (prevGroceryItemList) {
                    return !prevGroceryItemList.map(elm => JSON.stringify(elm)).includes(JSON.stringify(elm))
                }
            });

            let diffFromPreviousState = [...diffprevGroceryItemList, ...diff_groceryItemList]
            if (diffFromPreviousState.length !== 0) {

                let itemWasRemoved = _groceryItemList.filter(elem => elem.name === diffFromPreviousState[0].name).length === 0 ? true : false

                if (itemWasRemoved) {
                    let item = {
                        name: diffFromPreviousState[0].name,
                        quantity: 0,
                        bought: false
                    }
                    serverManager._updateGroceryListItems(item, props.id)
                }
                else {
                    let number = 0
                    if (diffFromPreviousState[1]) {
                        number = 1
                    }
                    serverManager._updateGroceryListItems(diffFromPreviousState[number], props.id)
                }

            }
        }

    }, [_groceryItemList])

    function addEelement(): void {
        let valueNotEmpty = refInputAddElement?.current?.value.length ? refInputAddElement?.current?.value.split(",") : false

        if (valueNotEmpty) {

            let newArray: GroceryItemProps[] = [];
            valueNotEmpty.map((item) => {
                if (_groceryItemList.some(_item => _item.name === item)) {
                    alert("Cet element est deja dans la liste de course")
                } else {

                    newArray.push({ name: item, quantity: 1, bought: false })
                }

            })
            setGroceryItemList(_groceryItemList.concat(newArray))
            if (refInputAddElement) {
                if (refInputAddElement.current) {
                    refInputAddElement.current.value = ""
                }
            }
        }
    }


    function createHubInviteUrl() {
        if (!props.id) return "Une erreur est survenue";
        try {
            window.focus();
            return `https://funnygrocery.leisuretime.fr/?${btoa(props.id)}`;
        } catch (error) {
            console.error("Error encoding the ID:", error);
            return "";
        }
    }

    function copyUrlToClipboard() {
        const text = createHubInviteUrl();
        navigator.clipboard.writeText(text).then(() => {
            alert("L'url d'invitation a bien été copié")
        })
            .catch(err => {
                alert("Une erreur est survenue lors de la copie de l'url d'invitation :" + err)
            });

    }

    return (
        <div className="ActiveGroceryList">
            <label className="ActiveGroceryList_Label">Liste en cours</label><br></br>
            <button className="ActiveGroceryList_Share_button" onClick={copyUrlToClipboard} >Share</button>
            <TextField id="standard-basic" label="Element" placeholder="Element" inputRef={refInputAddElement} className="ActiveGroceryList_Input" variant="standard" />
            <button className="ActiveGroceryList_Input_button" onClick={addEelement}>+</button>
            <ul className="ActivegroceryItemList">
                {
                    _groceryItemList.map((item) =>
                        <GroceryItem key={item.name} name={item.name} quantity={item.quantity} bought={item.bought} creatorName={props.creatorName} groceryActiveList={true} creatorEmail={props.creatorEmail} updateGroceryitem={updateGroceryitem}></GroceryItem>
                    )
                }
            </ul>
            <br></br>
            <button className="ActiveGroceryListDeleteListBtn" onClick={deleteAciiveGroceryList}>Supprimer la liste</button>
            <button className="ActiveGroceryList_End_Go_Shopping_Button" onClick={() => props.visible()}>Faire les courses</button>
            {/* {
                !_endlist ? <button className="ActiveGroceryList_End_GroceryList_Button" onClick={togleEndList}>Finaliser la liste courses</button> : <button className="ActiveGroceryList_End_Go_Shopping_Button" onClick={() => props.visible()}>Go faire les courses</button>
            } */}

        </div>
    )
}