import { useContext, useEffect, useReducer, useRef, useState } from "react"
import { GroceryItem, GroceryItemProps } from "./GroceryItem"
import { TextField } from "@mui/material"
import { firestore } from "../firebase_setup/firebase"
import { collection, Firestore, onSnapshot, Query } from "firebase/firestore"
import { json } from "stream/consumers"
import { serverManager } from "../controller/serverManager"
import { UserContext } from "../contexts/userContext"

export type LiveShoppingProps =
    {
        GroceryItemList: GroceryItemProps[],
        firestoreCollectionId: string
        visible: () => void
    }


export function LiveShopping(props: LiveShoppingProps) {
    const [shoppingEnded, setShoppingEnded] = useState<boolean>(false)
    const [groceryItems, setGroceryItems] = useState<GroceryItemProps[]>([])
    const refInputLieu = useRef<HTMLInputElement>(null)
    const refInputMontant = useRef<HTMLInputElement>(null)
    const { user, setUser } = useContext(UserContext)
    let first = true;
    let test2 = groceryItems
    function getItemsState() {
        return groceryItems
    }
    useEffect(() => {
        function getItems() {
            let docRef = collection(firestore, "groceryLists", props.firestoreCollectionId, "items")
            serverManager.setCanBeUpdate(props.firestoreCollectionId, false)
            const unsub = onSnapshot(docRef, (snapshot) => {

                let items = snapshot.docs.map(item => item.data()) as GroceryItemProps[]
                console.log(items)
                let newItem = snapshot.docChanges().map((change) => change.doc.data() as GroceryItemProps)[0]

                let itemsUpdated = [...items.filter((item) => { if (item.name !== newItem.name) { return item } })] as GroceryItemProps[]
                console.log(itemsUpdated)
                setGroceryItems([...itemsUpdated, newItem])

            });
            return () => unsub
        }
        getItems()
        return () => { serverManager.setCanBeUpdate(props.firestoreCollectionId, true) }
    }, [])

    function updateGroceryitem(name: string, quantity: number, bought: boolean) {
        let data = {
            name: name,
            quantity: quantity,
            bought: bought
        }
        serverManager._updateGroceryListItems(data, props.firestoreCollectionId)
    }

    async function endShopping() {
        let data =
        {
            place: refInputLieu?.current?.value,
            price: refInputMontant?.current?.value,
            active: false,
            finisher: user.uid
        }
        if (!refInputLieu?.current?.value.length || !refInputMontant?.current?.value.length) {
            return alert("N'oublie pas le lieu et le prix")
        }
        await serverManager.updateGroceryList(data, props.firestoreCollectionId)
        setTimeout(() => {
            window.location.reload()
        }, 500)

        //props.visible()
    }
    return (
        <div className="LiveShopping">
            <button onClick={props.visible} className="LiveShopping_close">X</button><br></br>
            <label className="LiveShopping_title" >Live Shopping</label>
            <ul className="LiveShopping_groceryItemList">
                {
                    groceryItems.map((item, index) => {
                        if (item.bought !== true) {
                            return <GroceryItem key={`${item.name}_${index}`} name={item.name} quantity={item.quantity} liveShopping={true} bought={item.bought} updateGroceryitem={updateGroceryitem} ></GroceryItem>
                        }
                    })
                }
            </ul>
            {
                shoppingEnded ? <><TextField type="texte" inputRef={refInputLieu} className="LiveShopping_input_lieu" label="Lieu" placeholder="Lieu" variant="standard" ></TextField><br></br>
                    <TextField type="number" inputRef={refInputMontant} className="LiveShopping_input_montant" label="Montant" placeholder="Montant" variant="standard"></TextField><br></br>
                    <button className="LiveShopping_validate" onClick={() => endShopping()}>Valider</button></> : <button className="LiveShopping_endShopping" onClick={() => setShoppingEnded(true)}>Terminer les courses</button>
            }
        </div>
    )
}



