import { useContext, useEffect, useState } from "react"
import { userType } from "../App"
import { CreateListForm } from "./CreateListForm"
import { GroceryListContainer } from "./GroceryListContainer"
import { LiveShopping } from "./LiveShopping"
import Button from '@mui/material/Button';
import { doc, setDoc, updateDoc } from "firebase/firestore"
import { serverManager, userCol } from "../controller/serverManager"
import { User } from "../types/User"
import { UserContext } from "../contexts/userContext"
import { GroceryListProps } from "./GroceryList"
import { ActiveGroceryListProps } from "./ActiveGroceryList"
import { GroceryListType } from "../types/GroceryList"
import { ParticipantType } from "../types/Participant"

type HomePageProps =
    {
        logOut: () => void,
        userType: User
    }
export function HomePage(props: HomePageProps) {

    const { user, setUser } = useContext(UserContext)

    let [firestoreCollectionId, setFirestoreCollectionId] = useState<string>("")
    let [createList, setCreateList] = useState<boolean>(false)
    let [liveShopping, setLiveShopping] = useState<boolean>(false)
    let [groceryList, setGroceryList] = useState<GroceryListType[]>([])
    let [activeGroceryList, setActiveGrocerylist] = useState<ActiveGroceryListProps[]>([])

    useEffect(() => {
        setUser(props.userType)
        async function getGrocerylists() {
            if (user.uid) {
                let currentUser = { displayName: user.displayName, email: user.email, selected: true, uid: user.uid } as ParticipantType
                let allGroceriesLists = await serverManager.getFiresotreGroceryLists(currentUser)
                console.log(allGroceriesLists)
                setGroceryList(allGroceriesLists.docs
                    .map(elem => {
                        let newProp = {
                            id: elem.id
                        }
                        return Object.assign(elem.data(), newProp) as GroceryListType
                    }))
            }

        }
        getGrocerylists()
    }, [user])

    function togleShowCreatelist(_createList: boolean) {
        setCreateList(!_createList)
    }

    useEffect(() => {
        if (firestoreCollectionId === "") return setLiveShopping(false)
        setLiveShopping(true)
    }, [firestoreCollectionId])

    function togleShowLiveShopping() {
        setFirestoreCollectionId("")
    }
    /*TODO
        - Ajouter la pp de l'utilisateur
        - Ajouter la creation de liste (Firestore)
        - Ajouter la recuperation de liste (Firestore)
        - Ajouter l'ajout de participants    
    */
    return (
        <>
            <h1 className="HomePage_title">Bienvenue {props.userType.displayName}</h1>
            {/* <h3 className="HomePage_GroceryListContainer_label">Voici un recapitulatif de tes listes de courses</h3> */}
            <button className="HomePage_button_create_list" onClick={() => togleShowCreatelist(createList)}>Creer une liste</button>
            <button className="HomePage_button_disconect" onClick={props.logOut}>Deconnexion</button>
            {/* <img className="HomePage_profile_picture" src={props.userType.photoUrl} alt="Profile"></img> */}
            <GroceryListContainer allGroceriesList={groceryList} setFirestoreCollectionId={setFirestoreCollectionId} ></GroceryListContainer><br></br>
            {createList && <><CreateListForm visible={() => togleShowCreatelist(createList)}></CreateListForm><br></br></>}
            {liveShopping && <><LiveShopping firestoreCollectionId={firestoreCollectionId} GroceryItemList={[]} visible={() => togleShowLiveShopping()}></LiveShopping></>}
        </>
    )
}
