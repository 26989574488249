import { Google } from '@mui/icons-material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
type loginFormProps = {
    login: () => void
}

export function LoginForm(props: loginFormProps) {
    return (
        <div className="loginForm">
            <div className='loginFormItem loginForm_account_icone'>
                <AccountCircleIcon sx={{ color: "#99E1D9", fontSize: 100 }}></AccountCircleIcon>
            </div>
            <div className='loginFormItem loginForm_label'>
                <h3>Bienvenu sur Funny Grocery</h3>
            </div>
            <div className='loginFormItem loginForm_connect_button'>
                <label onClick={props.login}>Se connecter avec Google</label>
            </div>
            <div className='loginFormItem loginForm_facebook_icone'>
                <Google sx={{ color: "", fontSize: 50 }} onClick={props.login}></Google>
            </div>
        </div>
    )
}