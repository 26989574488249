import { forwardRef, useState, useImperativeHandle, Ref, useEffect } from "react"
import { RefObject } from "./CreateListForm";
import { type } from "@testing-library/user-event/dist/type";
import { serverManager } from "../controller/serverManager";

export type GroceryItemProps = {
    name: string,
    quantity: number,
    creatorName?: string,
    creatorEmail?: string,
    liveShopping?: boolean,
    groceryActiveList?: boolean,
    buyer?: string,
    bought: boolean,
    delete?: (index: number) => void,
    updateGroceryitem?: (name: string, quantity: number, bought: boolean) => void,
}

export const GroceryItem = forwardRef((props: GroceryItemProps, ref: Ref<RefObject>) => {
    let [quantity, setQuantity] = useState(props.quantity)
    let [bought, setBought] = useState(props.bought)
    let active = props.groceryActiveList ? "active" : ""
    function increaseQuantity() {
        setQuantity(quantity + 1)
    }

    useEffect(() => {
        if (props.updateGroceryitem) {
            props.updateGroceryitem(props.name, quantity, bought)
        }
    }, [quantity]);

    useEffect(() => {
        if (props.updateGroceryitem) {
            props.updateGroceryitem(props.name, quantity, bought)
        }
    }, [bought])

    useImperativeHandle(ref, () => ({ getGroceryItem }));

    function getGroceryItem() {
        return { quantity: quantity, name: props.name }
    }
    function decreseQuantity() {
        setQuantity(quantity - 1)
    }

    return (
        <>
            {quantity > 0 &&
                <li className={`${active}GroceryItem`}><div className={`${active}GroceryItemName`} >{props.name}</div><div className={`${active}GroceryQuantity`}>{quantity} {props.buyer}</div>
                    {
                        props.groceryActiveList && <AddAndRemoveButton increaseQuantity={increaseQuantity} decreseQuantity={decreseQuantity}></AddAndRemoveButton>
                    }
                    {
                        props.liveShopping && <button className={`${active}GroceryItem_by_it`} onClick={() => setBought(true)}>Acheter</button>
                    }
                </li>
            }
        </>
    )
})

type addAndRemoveButtonProps = {
    increaseQuantity: () => void,
    decreseQuantity: () => void
}

function AddAndRemoveButton(props: addAndRemoveButtonProps) {
    return (
        <>
            <button className="GroceryItem_add_button" onClick={props.increaseQuantity}>+</button>
            <button className="GroceryItem_Substract_button" onClick={props.decreseQuantity}>-</button>
        </>
    )
}