import { collection, addDoc, CollectionReference, DocumentData, doc, updateDoc, getDoc, setDoc, getDocs, query, where, deleteDoc, orderBy, writeBatch, collectionGroup, arrayUnion } from "firebase/firestore";
import { User } from "../types/User";
import { firestore } from "../firebase_setup/firebase";
import { GroceryListType } from "../types/GroceryList";
import { Item } from "../types/Item";
import { GroceryItemProps } from "../components/GroceryItem";
import { userType } from "../App";
import { ParticipantType } from "../types/Participant";

const createCollection = <T = DocumentData>(collectionName: string) => {
    return collection(firestore, collectionName) as CollectionReference<T>
}

export const userCol = createCollection<User>('users')
export const groceryListCol = createCollection<GroceryListType>('groceryLists')
export const itemCol = createCollection<Item>("items")
export class serverManagerClass {
    async deleteGroceryList(id: string) {
        await deleteDoc(doc(firestore, "groceryLists", id)).catch(
            (error) => {
                console.log(error, "error delete grocery list")
            }
        )
    }
    async joinActiveGroceryList(currentUser: User, groceryListId: string) {
        const GroceryListsRef = doc(firestore, "groceryLists", groceryListId);
        return await updateDoc(GroceryListsRef, {
            participants: arrayUnion(currentUser.uid)
        }).catch(
            (error) => {
                console.log(error, "error joining active grocery list")
                return ("error")
            }
        )
    }

    async updateFirestoreUser(dataUser: User) {
        const userRef = doc(userCol, dataUser.uid)
        const docSnapUser = await getDoc(userRef)
        if (docSnapUser.exists()) {
            await updateDoc(userRef, dataUser).catch(
                (error) => {
                    console.log(error, "error update user")
                }
            )
        } else {
            await setDoc(userRef, dataUser).catch(
                (error) => {
                    console.log(error, "error create new user")
                }
            )
        }
    }
    async createList(dataList: GroceryListType) {
        console.log(dataList.items)

        let newList = await addDoc(collection(firestore, "groceryLists"), dataList).catch(
            (error) => {
                console.log(error, "error create list")
            }
        )
        console.log(newList)
        let ItemsBatch = writeBatch(firestore);
        dataList.items.forEach(async (item) => {
            ItemsBatch.set(doc(collection(firestore, "groceryLists", newList ? newList.id : "", "items")), item)
        })
        await ItemsBatch.commit();
    }
    async getFirestoreUsers(data: ParticipantType) {
        let userRef = collection(firestore, "users")
        let userQuery = query(userRef, where("uid", "!=", data.uid));
        return await getDocs(userQuery)
    }

    async getFiresotreGroceryLists(user: ParticipantType) {
        let GroceryListsRef = collection(firestore, "groceryLists");
        let GroceryListsQuery = query(GroceryListsRef, where("participants", "array-contains", user.uid), orderBy("date", "desc"), orderBy("active", "desc"))
        return await getDocs(GroceryListsQuery)
    }

    async updateGroceryList(data: any, id: string) {
        /*TODO 
        recuperer l'id du document et update le champ item
        - Check si l'item existe déja 
        - si il existe rajouter ou deminuer la quatité
        - suprimer si quantité egal 0
        - si il n'existe pas l'ajouter
        */
        console.log("update grocery List")
        return await updateDoc(doc(firestore, "groceryLists", id), {
            place: data.place,
            price: data.price,
            active: data.active,
            finisher: data.finisher
        }).catch(
            (error) => {
                console.log(error, "error update grocery list")
            }
        )
    }

    async setCanBeUpdate(fireStoreGroceryListId: string, data: boolean) {
        let ListRef = doc(firestore, "groceryLists", fireStoreGroceryListId)
        await updateDoc(ListRef, {
            canBeUpdate: data
        }).catch(
            (error) => {
                console.log(error, "error update cabBeUpdate")
            }
        )
    }
    async _updateGroceryListItems(data: GroceryItemProps, fireStoreGroceryListId: string) {
        /*TODO 
        recuperer l'id du document et update le champ item
        - Check si l'item existe déja 
        - si il existe rajouter ou deminuer la quatité
        - suprimer si quantité egal 0
        - si il n'existe pas l'ajouter
        */
        let ListRef = await getDoc(doc(firestore, "groceryLists", fireStoreGroceryListId))
        if (ListRef.data()?.canBeUpdate === false && data.bought === false) {
            // return alert("Queslqu'un est deja en train de faire les coures")
        }

        let itemRef = collection(firestore, "groceryLists", fireStoreGroceryListId, "items")
        let itemQuery = query(itemRef, where("name", "==", data.name));
        let querySnapshot = await getDocs(itemQuery);
        if (querySnapshot.size === 0) {
            console.log("l'ingredient n'est pas dans deja dans la bdd")
            return await setDoc(doc(collection(firestore, "groceryLists", fireStoreGroceryListId, "items")), data);
        }
        else {
            querySnapshot.forEach(async (item) => {
                if (data.quantity === 0) {
                    return await deleteDoc(doc(firestore, "groceryLists", fireStoreGroceryListId, "items", item.id)).catch(
                        (error) => {
                            console.log(error, "error deleteing grocery item")
                        }
                    );
                }
                return await updateDoc(doc(firestore, "groceryLists", fireStoreGroceryListId, "items", item.id), {
                    quantity: data.quantity, bought: data.bought
                }).catch(
                    (error) => {
                        console.log(error, "error update grocery item")
                    }
                )
            });
        }
    }

    async getGroceryListItems(fireStoreGroceryListId: string) {
        let items = await getDocs(collection(firestore, "groceryLists", fireStoreGroceryListId, "items"))
        return items.docs.map((item) => item.data())
    }

    async getUser(firestoreUserId: string) {
        return await (await getDoc(doc(firestore, "users", firestoreUserId))).data() as userType
    }
}
export const serverManager = new serverManagerClass()