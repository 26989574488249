import { create } from "domain";
import { createContext, Dispatch, SetStateAction, ReactNode, useState } from "react";
import { User } from "../types/User";

export interface UserContextInterface {
    user: User,
    setUser: Dispatch<SetStateAction<User>>
}

const defaultState =
    {
        user: {
            displayName: "",
            email: "",
            photoUrl: "",
            uid: "",
            createdAt: 0,
            lastLogInAt: 0,
            isLoggedIn: false
        },
        setUser: (user: User) => { }
    } as UserContextInterface

export const UserContext = createContext(defaultState)

type userProviderProps =
    {
        children: ReactNode
    }

export function UserProvider({ children }: userProviderProps) {
    const [user, setUser] = useState<User>({
        displayName: "",
        email: "",
        photoUrl: "",
        uid: "",
        createdAt: 0,
        lastLogInAt: 0,
        isLoggedIn: false
    })

    return (
        <UserContext.Provider value={{ user, setUser }}>
            {children}
        </UserContext.Provider>
    )
}