import { useState } from "react"
import { GroceryListType } from "../types/GroceryList"
import { ActiveGroceryList, ActiveGroceryListProps } from "./ActiveGroceryList"
import { GroceryList, GroceryListProps } from "./GroceryList"

type GroceryListContainerProps =
    {
        allGroceriesList: GroceryListType[],
        setFirestoreCollectionId: (id: string) => void
    }

export function GroceryListContainer(props: GroceryListContainerProps) {
    //mettre visible ici
    let noListYest = props.allGroceriesList.length === 0 ? true : false

    function _visible(id: string) {
        if (id.trim().length < 1) return
        props.setFirestoreCollectionId(id)
    }

    return (
        <div className="GroceryListContainer">
            {noListYest === true ? <label className="GroceryListContainer_noListYet">Chargement</label> : <></>}
            {
                props.allGroceriesList.map((item, index) => {
                    return item.active ?
                        <ActiveGroceryList key={index + item.date} id={item.id || ""} visible={() => _visible(item.id || "")} creatorName={item.creator} creatorEmail={""} date={item.date} participants={[]} items={item.items} /> :
                        <GroceryList key={index + item.date} id={item.id} name={item.creator} date={item.date} place={item.place} price={item.price} items={item.items} />
                })
            }
        </div>
    )
}
