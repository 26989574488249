/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState, useContext } from 'react';
import './App.css';
import { LoginForm } from './components/LoginForm';
import { signInWithPopup, FacebookAuthProvider, GoogleAuthProvider } from 'firebase/auth';
import { auth } from './firebase_setup/firebase'
import { HomePage } from './components/HomePage';
import { User } from './types/User';
import { serverManager } from './controller/serverManager';
import { UserContext, UserProvider } from './contexts/userContext';
import { ParticipantType } from './types/Participant';

export type userType = {
  displayName: string,
  email: string,
  photoUrl: string,
  uid: string,
  createdAt: string,
  lastLogInAt: string,
  isLoggedIn: boolean
}

function App() {

  const [user, setUser] = useState<User>({
    displayName: "",
    email: "",
    photoUrl: "",
    uid: "",
    createdAt: 0,
    lastLogInAt: 0,
    isLoggedIn: false
  })

  const groceryListIdToJoin = atob((window.location.search.slice(1)))

  const signInWithGoogle = () => {

    const provider = new GoogleAuthProvider();

    signInWithPopup(auth, provider).then(() => {

      let _user: User = {
        displayName: auth.currentUser?.displayName || "",
        email: auth.currentUser?.email || "",
        photoUrl: auth.currentUser?.photoURL || "",
        uid: auth.currentUser?.uid || "",
        createdAt: Date.parse(auth.currentUser?.metadata.creationTime || "") || 0,
        lastLogInAt: Date.parse(auth.currentUser?.metadata.lastSignInTime || "") || 0,
        isLoggedIn: true
      }
      setUser(_user)
      serverManager.updateFirestoreUser(_user)
      localStorage.setItem('user', JSON.stringify(_user));
    }).catch((eror) => {
      switch (eror.code) {
        case "auth/account-exists-with-different-credential":
          alert("Vous avez déjà un compte avec cette adresse email. Veuillez vous connecter avec votre compte Facebook")
          break;
        case "auth/credential-already-in-use":
          alert("Vous avez déjà un compte avec cette adresse email. Veuillez vous connecter avec votre compte Facebook")
          break;
        case "auth/email-already-in-use":
          alert("Vous avez déjà un compte avec cette adresse email. Veuillez vous connecter avec votre compte Facebook")
          break;
        default:
          alert("Une erreur est survenue, veuillez réessayer :" + eror.code)
          break;
      }
    })
  }

  function signOut() {
    auth.signOut()
    let currentUser: User = JSON.parse(localStorage.getItem('user') || "{}")
    currentUser.isLoggedIn = false;
    setUser(currentUser)
    localStorage.removeItem('user')
  }

  async function joinList(user: User) {
    const allGroceriesLists = await serverManager.getFiresotreGroceryLists(user as unknown as ParticipantType)
    const allGroceriesListsIds = allGroceriesLists.docs.some(elem => elem.id === groceryListIdToJoin)
    console.log(allGroceriesListsIds)
    if (!allGroceriesListsIds) {
      const joinList = await serverManager.joinActiveGroceryList(user, groceryListIdToJoin)
      if (joinList !== "error") {
        setTimeout(() => {
          window.location.reload()
        }, 200)
      }
    }
  }

  useEffect(() => {
    let currentUser: User = JSON.parse(localStorage.getItem('user') || "{}")
    if (currentUser.isLoggedIn) {
      if (groceryListIdToJoin) {
        joinList(currentUser)
      }
      setUser(currentUser)
    }
  }, [user.isLoggedIn])



  return (
    <UserProvider>
      {
        user.isLoggedIn ? <HomePage logOut={signOut} userType={user}></HomePage> : <LoginForm login={signInWithGoogle}></LoginForm>
      }

    </UserProvider>
    /*<div className="App">
      
    </div>*/
  );

}

export default App;
/*TODO 
- Ajouter firebase à lapplication DONE
- Se connecter avec facebook DONE
- remplacer les informations du local storage par un contexte
- merge la liste des props item ainsi que les items recuperé à partir du input
- color palette : 
  - #99E1D9
  - #F0F7F4
  - #00487C
  - 545e75
*/

