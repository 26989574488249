import { useContext, useEffect, useRef, useState } from "react"
import { GroceryItem, GroceryItemProps } from "./GroceryItem"
import { Participant } from "./Participant"
import { TextField } from "@mui/material"
import { GroceryListType } from "../types/GroceryList"
import { serverManager, userCol } from "../controller/serverManager"
import { UserContext } from "../contexts/userContext"
import { User } from "../types/User"
import { ParticipantType } from "../types/Participant"
import { userType } from "../App"

export type CreateListFormProps = {
    visible: () => void
}

export interface RefObject {
    getGroceryItem: () => void
}

export function CreateListForm(props: CreateListFormProps) {
    const { user, setUser } = useContext(UserContext)
    const refInputAddElement = useRef<HTMLInputElement>(null)
    const [allStringItems, setAllStringItems] = useState<string[] | undefined>([])
    const [filteredParticipantList, setfilteredParticipantList] = useState<ParticipantType[]>([]);
    const [allGroceryItems, setallGroceryItems] = useState<{ name: string, quantity: number, bought: boolean }[]>([])

    const currentUser = { displayName: user.displayName, email: user.email, selected: true, uid: user.uid } as ParticipantType
    const groceryItemStateRef = useRef<RefObject>(null);

    const filterBySearch = (event: { target: { value: any } }) => {
        let query = event.target.value;
        let updatedList = [...filteredParticipantList].filter((item) => {

            return item.displayName.toLowerCase().indexOf(query.toLowerCase()) !== -1 || item.email.toLowerCase().indexOf(query.toLowerCase()) !== -1;
        });
        setfilteredParticipantList(updatedList);
    };

    function removeElement(index: number): void {

        let updatedList = [...filteredParticipantList].filter((_, i) => i !== index)

        setfilteredParticipantList(updatedList);
    }

    function selectParticipant(index: number, selected: boolean): void {
        return setfilteredParticipantList(filteredParticipantList.map((item, _index) => {
            if (_index === index) {
                return { ...item, selected: !selected }
            }
            return item
        }))
    }

    function updateGroceryitem(name: string, quantity: number) {

        let newItemObj = {
            name: name,
            quantity: quantity,
            bought: false
        }
        let index = allGroceryItems.findIndex((item) => item.name === newItemObj.name)

        if (index === -1) {
            return setallGroceryItems([...allGroceryItems, newItemObj])
        }

        return setallGroceryItems(allGroceryItems.map((item) => {


            if (item.name === newItemObj.name) {
                return { ...item, newItemObj }
            }
            return item
        }))
        //setallGroceryItems({ ...allGroceryItems, [name]: newItem });
    }

    function addEelement() {
        let valueNotEmpty = refInputAddElement?.current?.value?.length ? refInputAddElement?.current?.value?.split(",") : false
        if (valueNotEmpty && allStringItems?.includes(valueNotEmpty[0])) alert("Cet element est deja dans la liste")
        if (valueNotEmpty && !allStringItems?.includes(valueNotEmpty[0])) {
            let newArray = allStringItems?.concat(valueNotEmpty)
            setAllStringItems(newArray)
            if (refInputAddElement) {
                if (refInputAddElement.current) {
                    refInputAddElement.current.value = ""
                }
            }
            return updateGroceryitem(valueNotEmpty[0], 1)
        }
        // return updateGroceryitem()
    }

    async function createNewList() {
        if (!allGroceryItems.length) {
            alert("Ajoute au moins un element dans ta liste")
        }
        else {
            let dataList: GroceryListType = {
                creator: user.uid,
                date: Date.now(),
                place: "",
                price: 0,
                active: true,
                canBeUpdate: true,
                items: allGroceryItems,
                participants: [...filteredParticipantList.filter((item) => item.selected === true), currentUser].map(user => user.uid)
            }
            await serverManager.createList(dataList)
            props.visible()
            // eslint-disable-next-line no-restricted-globals
            alert('votre liste a été cree avec succes')

            window.location.reload()


        }
    }

    useEffect(() => {
        async function getAllUSers() {
            let allUsers = await serverManager.getFirestoreUsers(currentUser)
            setfilteredParticipantList(allUsers.docs.map(user => {
                let newPropsObj = {
                    selected: false
                };
                return Object.assign(user.data(), newPropsObj) as ParticipantType;
            }))
        }
        getAllUSers();
    }, [])

    return (
        <div className="CreateListForm">
            <label className="CreateListForm_label" >Nouvelle Liste de course</label>
            <button onClick={props.visible} className="CreateListForm_close">X</button>
            <TextField variant="standard" label="Elements" inputRef={refInputAddElement} type="texte" className="CreateListForm_input_list_element" placeholder="Elements"></TextField>
            <button onClick={addEelement} className="CreateListForm_addElement_button">Ajouter</button>
            <ul className="CreateListForm_groceryItems">
                {allStringItems &&
                    allStringItems.map((item, index) => <GroceryItem key={index} ref={groceryItemStateRef} bought={false} name={item.trim()} groceryActiveList={true} quantity={1} updateGroceryitem={updateGroceryitem}></GroceryItem>)
                }
            </ul>
            <TextField variant="standard" label="Participants" type="texte" onChange={filterBySearch} className="CreateListForm_input_participants" placeholder="Participants"></TextField>
            <ul className="CreateListForm_participants">

                {filteredParticipantList.map((item, index) => (
                    <Participant key={index} index={index} displayName={item.displayName} email={item.email} uid={item.uid} selected={item.selected} selectParticipant={selectParticipant}></Participant>
                )
                )}
            </ul>
            <button className="CreateListForm_input_create_list_button" onClick={createNewList}>Créer la liste de course</button>
        </div >
    )
}
