import { findAllByTestId } from "@testing-library/react";
import { useEffect, useState } from "react";

export type ParticipantProps = {
    displayName: string,
    email: string,
    selected: boolean,
    index?: number,
    uid: string
    selectParticipant?: (index: number, selected: boolean) => void,
}

export function Participant(props: ParticipantProps) {

    const [colorSelectedParticipant, setColorSelectedParticipant] = useState("#FFC5C1")
    const [isParticipantSelected, setisParticipantSelected] = useState(props.selected)

    function selectParticipant() {

        if (props.selectParticipant !== undefined && props.index !== undefined) {
            props.selectParticipant(props.index, isParticipantSelected)
        }
        setisParticipantSelected(!isParticipantSelected)
    }
    useEffect(() => {
        if (isParticipantSelected) {
            setColorSelectedParticipant("#1AD1A1")
        }
        else {
            setColorSelectedParticipant("#FFC5C1")
        }
    }, [isParticipantSelected])


    return (
        <li className="Participants_li" style={{ backgroundColor: colorSelectedParticipant }} onClick={selectParticipant}>
            <label className="Participants_displayName">{props.displayName}</label><br></br>
            <label className="Participants_email">{props.email}</label>
        </li>
    )
}


