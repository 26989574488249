import { useEffect, useState } from "react"
import { userType } from "../App"
import { serverManager } from "../controller/serverManager"
import { frenchDate } from "../utils"
import { GroceryItem } from "./GroceryItem"
import { GroceryItemProps } from "./GroceryItem"
export type GroceryListProps =
    {
        id?: string,
        name?: string,
        date: number,
        place: string,
        price: number,
        canBeUpdate?: boolean,
        items: GroceryItemProps[]
    }
export function GroceryList(props: GroceryListProps) {
    let [items, setitems] = useState<GroceryItemProps[]>([])
    let [listCreator, setListCreator] = useState<userType>()
    useEffect(() => {
        async function getItems() {
            if (props.id) {
                let items = await serverManager.getGroceryListItems(props.id);
                setitems(items.map(item => item as GroceryItemProps))
            }
            if (props.name) {
                setListCreator(await serverManager.getUser(props.name))
            }
        }
        getItems()
    }, [])

    async function deleteGroceryList() {

        const response = window.confirm("Voulez-vous vraiment supprimer cette liste ?");

        if (response) {
            if (props.id) {
                await serverManager.deleteGroceryList(props.id)
                setTimeout(() => {
                    window.location.reload()
                }, 200)
            }
        } else {
            alert("Cancel was pressed");
        }
    }

    return (
        <div className="GroceryList">
            <label className="GroceryList_name">{listCreator?.displayName}</label>
            <label className="GroceryList_date">{frenchDate(props.date)}</label>
            <ul className="GroceryList_groceryItemList">
                {
                    items.map((item, index) =>
                        <GroceryItem key={item.name + index + item.quantity} name={item.name} quantity={item.quantity} bought={item.bought}></GroceryItem>
                    )
                }
            </ul>
            <label className="GroceryList_place">{props.place}</label>
            <label className="GroceryList_price">{props.price} €</label>
            <label className="GroceryListDeleteListBtn" onClick={deleteGroceryList}>Supprimer la liste</label>
        </div>
    )
}


